<template>
    <b-modal v-model="localShowModal" centered :title="'Agregar Usuario - ' + subtitle" hide-footer no-close-on-backdrop
        size="lg">
        <b-overlay rounded responsive variant="transparent" opacity="0.33" blur="2px">
            <div class="row">
                <div class="col-12">
                    <validation-observer ref="createForm" #default="{ invalid }">
                    <b-form @submit.prevent="createUser">
                        <b-form-group label="">
                            <b-row>
                                <b-col class="mb-1" xl="12" md="12" sm="12">
                                    <b-form-group label="Colegio" label-for="colegio">
                                        <validation-provider #default="{ errors }" name="colegio" rules="required">
                                            <v-select class="mx-select" v-model="formData.school_id"
                                                :disabled="fromParent"
                                                 :state="errors.length > 0 ? false : null"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" rules="required"
                                                :options="schoolsOptions" :reduce="(item) => item.id"
                                                placeholder="Seleccione un colegio" label="name" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!--  <b-col class="mb-1" xl="6" md="6" sm="12">
                                    <v-select id="typeUser" v-model="formData.typeUser"
                                        :disabled="typeReadOnly"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                                        :reduce="(typeUser) => typeUser.value" placeholder="Tipo de Usuario"
                                        :options="typeUserOptions" />
                                </b-col> -->
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="mb-2 mt-2">
                                    <h5 class="mb-0">Informacion de Usuario</h5>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group label="Nombres" label-for="name">
                                        <validation-provider #default="{ errors }" name="nombre" rules="required">
                                            <b-form-input v-model="formData.name"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group label="Apellidos" label-for="last_name">
                                        <validation-provider #default="{ errors }" name="apellido" rules="required">
                                            <b-form-input v-model="formData.last_name"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group label="Fecha de nacimiento" label-for="birthday">
                                        <validation-provider #default="{ errors }" name="fecha de nacimiento"
                                            rules="required">
                                            <date-picker class="w-100" v-model="formData.birthday" type="date"
                                                :default-value="new Date()"
                                                :disabled-date="disabledBeforeToday"></date-picker>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group label="Cedula" label-for="ci">
                                        <validation-provider #default="{ errors }" name="ci" rules="required">
                                            <b-form-input v-model="formData.ci"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12" v-if="typeUser == 'student'">
                                    <b-form-group label="Grado" label-for="grado">
                                        <validation-provider #default="{ errors }" name="grado" rules="required">
                                            <b-form-input id="grado" v-model="formData.grado"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12" v-if="typeUser == 'student'">
                                    <b-form-group label="Sección" label-for="section">
                                        <validation-provider #default="{ errors }" name="section" rules="required">
                                            <b-form-input id="section" v-model="formData.section"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12" v-if="typeUser == 'student'">
                                    <b-form-group label="Aula" label-for="aula">
                                        <validation-provider #default="{ errors }" name="aula" rules="required">
                                            <b-form-input id="aula" v-model="formData.aula"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6" lg="4" sm="12">
                                  <b-form-group label="Telefono">
                                    <vue-tel-input v-model="formData.phone" @validate="validateNumber" />
                                   </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group label="Correo electronico" label-for="email">
                                        <validation-provider #default="{ errors }" name="email" rules="required|email">
                                            <b-form-input id="email" v-model="formData.email"
                                                :state="errors.length > 0 ? false : null" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12" v-if="!fromParent">

                                    <b-form-group label="Billetera">
                                        <validation-provider #default="{ errors }" name="billetera" rules="required">
                                            <v-select v-model="formData.wallet.type"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="[
                                                    { id: 1, name: 'Prepago' },
                                                    { id: 2, name: 'Pospago' },
                                                ]" label="name" :reduce="(item) => item.name" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>



                                </b-col>



                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group>
                                        <validation-provider #default="{ errors }" name="contraseña" vid="password"
                                            rules="required">
                                            <label>Contraseña</label>
                                            <b-input-group class="input-group-merge"
                                                :class="errors.length > 0 ? 'is-invalid' : null">
                                                <b-form-input id="register-password" v-model="formData.password"
                                                    class="form-control-merge" :type="passwordFieldType"
                                                    :state="errors.length > 0 ? false : null"
                                                    name="register-password" />
                                                <b-input-group-append is-text>
                                                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                        @click="togglePasswordVisibility" />
                                                </b-input-group-append>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="4" sm="12">
                                    <b-form-group>
                                        <validation-provider #default="{ errors }" name="contraseña" vid="c_password"
                                            rules="required|confirmed:password">
                                            <label>Confirmar contraseña</label>
                                            <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                                                <b-form-input id="register-c_password" v-model="formData.c_password"
                                                    class="form-control-merge" :type="passwordFieldType"
                                                    :state="errors.length > 0 ? false : null"
                                                    name="register-c_password" />
                                                <b-input-group-append is-text>
                                                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                                                        @click="togglePasswordVisibility" />
                                                </b-input-group-append>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" lg="12" sm="12">
                                    <b-form-group label="Opciones" v-if="formData.wallet.type == 'Pospago'">
                                        <b-button :variant="selectedOption === 'days' ? 'success' : 'primary'"
                                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                                            @click="selectOption('days')">
                                            Agregar días de préstamo
                                        </b-button>
                                        <b-button :variant="selectedOption === 'limit' ? 'success' : 'primary'"
                                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                                            @click="selectOption('limit')">
                                            Agregar límite de crédito
                                        </b-button>

                                        <div v-if="selectedOption === 'days'">
                                            <validation-provider #default="{ errors }" name="billetera"
                                                rules="required">
                                                <br />
                                                <v-select placeholder="Días de préstamo" v-model="formDataV1.days"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsDays" label="name" :reduce="(item) => item.id" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </div>

                                        <b-form-group v-if="selectedOption === 'limit'">
                                            <br />
                                            <b-form-input placeholder="Límite de crédito" type="number"
                                                v-model="formDataV1.max_limit" />
                                        </b-form-group>
                                    </b-form-group>
                                </b-col>

                            </b-row>
                            <b-row class="justify-content-evenly mt-3">
                                <b-button type="submit" variant="primary">
                                    Agregar
                                </b-button>
                                <b-button @click="closeModal" variant="secondary">
                                    Cancelar
                                </b-button>
                            </b-row>


                        </b-form-group>
                    </b-form>
                </validation-observer>
                </div>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import {
    BButton,
    BRow,
    BCol,
    VBModal,
    BFormInput,
    BFormGroup,
    BForm,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import useJwt from "@/auth/jwt/useJwt"
import 'vue2-datepicker/locale/es';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { required } from "vee-validate/dist/rules";
export default {
    name: "CreateStudent",
    components: {
        BFormInput,
        BFormGroup,
        vSelect,
        BButton,
        BRow,
        BCol,
        BForm,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
        DatePicker,
        BInputGroup,
        BInputGroupAppend,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            selectedOption: null,
            isValidateNumber: false,
            optionsDays: [
                { id: 1, name: "1 día" },
                { id: 2, name: "2 días" },
                { id: 3, name: "3 días" },
                { id: 4, name: "4 días" },
                { id: 5, name: "5 días" },
                { id: 6, name: "6 días" },
                { id: 7, name: "7 días" },
                { id: 8, name: "8 días" },
                { id: 9, name: "9 días" },
                { id: 10, name: "10 días" },
                { id: 11, name: "11 días" },
                { id: 12, name: "12 días" },
                { id: 13, name: "13 días" },
                { id: 14, name: "14 días" },
                { id: 15, name: "15 días" },
                { id: 16, name: "16 días" },
                { id: 17, name: "17 días" },
                { id: 18, name: "18 días" },
                { id: 19, name: "19 días" },
                { id: 20, name: "20 días" },
                { id: 21, name: "21 días" },
                { id: 22, name: "22 días" },
                { id: 23, name: "23 días" },
                { id: 24, name: "24 días" },
                { id: 25, name: "25 días" },
                { id: 26, name: "26 días" },
                { id: 27, name: "27 días" },
                { id: 28, name: "28 días" },
                { id: 29, name: "29 días" },
                { id: 30, name: "30 días" },
                { id: 31, name: "31 días" },
            ],
            localShowModal: this.showModal,
            schoolsOptions: [],
            subtitle: null,
            formData: {
                id_card: null,
                name: null,
                last_name: null,
                email: null,
                password: null,
                degree: null,
                section: null,
                typeUser: null,
                classroom: null,
                phone: null,
                birthday: null,
                ci: null,
                representative_id: null,
                school_id: null,
                wallet: {
                    type: null,
                    max_limit: null,
                },
                observation: null,
                max_limit: null,
            },
            formDataV1: {
                days: null,
                max_limit: null,
            },
            activeDaysOrLimit: false,
            representativeOptions: [],
            typeUserOptions: [
                {
                    value: 'student',
                    text: 'Estudiante',
                },
                {
                    value: 'representative',
                    text: 'Representante',
                },
                {
                    value: 'teacher',
                    text: 'Profesor',
                },
                {
                    value: 'cashier',
                    text: 'Cajero',
                },
            ],
        }
    },
    props: {
        typeUser: {
            type: String,
            required: true,
        },
        college: {
            type: Object,
            required: false
        },
        showModal: {
            required: true
        },
        typeReadOnly: {
            required: false
        },
        fromParent: {
            required: true
        },
        schoolId: {
            required: false
        }

    },
    watch: {
        showModal(newVal, oldVal) {
            // Aquí puedes agregar la lógica que deseas ejecutar cuando showModal cambie
            this.localShowModal = newVal
            for (let data of this.typeUserOptions) {
                if (data.value === this.typeUser) {
                    this.formData.typeUser = data.value
                    this.subtitle = data.text
                }
            }
            for (let schools of this.schoolsOptions) {
                if (schools.id === this.schoolId) {
                    this.formData.school_id = schools
                }
            }
        },
        typeUser(newVal, oldVal) {
        },
        closeModal(newVal, oldVal) {
            console.log("closing modals")
        },
        schoolId(newVal) {
            // Si cambia el schoolId, actualiza el formData para autoseleccionarlo
            if (newVal) {
                this.formData.school_id = newVal;
            }
        },
    },
    created() {
        this.getSchools();
    },
    methods: {
        validateNumber(value) {
            this.isValidateNumber = value.valid
        },
        selectOption(option) {
            this.selectedOption = option;
        },
        /**
         * method to submit the new user
         */
        disabledBeforeToday(date) {
            const today = new Date();
            return date >= new Date(today.getTime())
        },
        async createUser() {
            if (!this.isValidateNumber) {
             this.message(
                "AlertCircleIcon",
                "danger",
                "El número de teléfono no es válido"
        );
        return;
            }
            const valid = await this.$refs.createForm.validate();
            if (!valid) return;
            this.formData.formDataV1 = this.formDataV1;
            if (this.fromParent) {
                this.formData.school_id = this.formData.school_id.id
            }
            try {
                this.loadingRegister = true
                let response = await useJwt.register(this.formData)
                if (response.status === 200) {
                    if (!this.fromParent) {
                        this.$emit('refresh')
                    } else {
                        this.$emit('autoSelect', response.data)
                    }
                    this.formData = {
                        id_card: null,
                        name: null,
                        last_name: null,
                        email: null,
                        password: null,
                        degree: null,
                        section: null,
                        typeUser: null,
                        classroom: null,
                        phone: null,
                        birthday: null,
                        ci: null,
                        representative_id: null,
                        school_id: null,
                        wallet: {
                            type: null,
                            max_limit: null,
                        },
                        observation: null,
                        max_limit: null,
                    },
                        this.formDataV1 = {
                            days: null,
                            max_limit: null,
                        }
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: "Registro",
                            icon: "CheckCircleIcon",
                            variant: "success",
                            text: "Usuario registrado satisfactoriamente",
                        },
                    })
                }
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: "Error",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                        text: error.response.data.message,
                    },
                })
            } finally {
                this.loadingRegister = false;

            }
        },
        closeModal() {
            this.localShowModal = false;
            this.$emit('closeModal')
        },
        async getSchools() {
            try {
                const response = await this.$store.dispatch('schools/index')
                this.schoolsOptions = response.data
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
        },
    }

}
</script>

<style scoped>
/* Aquí puedes agregar estilos específicos para este componente */
.justify-content-evenly {
    justify-content: space-evenly;
}
</style>